// api.ts

import axios, { AxiosError } from "axios";
import store from "redux/store";
// import { useDispatch } from "react-redux";
import { setLoading } from "redux/features/loader";
import { getUrl } from "utils/UrlMap";
import { logException } from "componnets/atoms/Logger";
import { jwtDecode } from "jwt-decode";
import { getGuidData } from "./utils";

interface DecodedToken {
  exp: number; // Expiration time claim (typically in Unix time)
}
const Proxy_url =
  process.env.REACT_BACKEND_URL ??
  "https://azapp-api-epc-uat-01.azurewebsites.net";
// "http://localhost:8080";

const axiosEcpInstance = axios.create({
  baseURL: Proxy_url,
  // withCredentials: true
  // https://dev-epcbackendapi.stewart.com/webhook
});

function isTokenExpired(token: string): boolean {
  try {
    const decoded: DecodedToken = jwtDecode<DecodedToken>(token);
    return Date.now() >= decoded.exp * 1000;
  } catch (error) {
    console.error("Failed to decode JWT:", error);
    return true; // Assume expired if there's an error decoding the token
  }
}
// Function to create a new access token if needed
export async function createAccessToken(): Promise<Record<string, string>> {
  store.dispatch(setLoading({ loading: true }));

  // eslint-disable-next-line no-useless-catch
  const Path = getUrl("createToken");

  // eslint-disable-next-line no-useless-catch
  try {
    const response = await axios.post(`${Proxy_url}${Path}`);
    const newAccessToken: string = response.data.access_token;
    // Example with domain and path settings

    sessionStorage.setItem("accessToken", newAccessToken);

    return { newAccessToken };
  } catch (error) {
    logException("Err generating accessToken [axiosEcp]", {
      error: error || {},
      message: "Err generating accessToken [axiosEcp]",
    });
    throw error;
  } finally {
    store.dispatch(setLoading({ loading: false }));
  }
}

// Maintaining a counter to track pending requests
let pendingRequests = 0;

axiosEcpInstance.interceptors.request.use(
  async (config) => {
    pendingRequests++;
    store.dispatch(setLoading({ loading: true }));
    const state: any = store.getState();
    const { PricingGuid, IsCTS } = getGuidData(state);
    let accessToken = sessionStorage.getItem("accessToken");

    if (!accessToken || isTokenExpired(accessToken)) {
      const { newAccessToken } = await createAccessToken();
      accessToken = newAccessToken; // Update local variable with new token
      sessionStorage.setItem("accessToken", accessToken); // Update session storage
    }

    if (accessToken && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    if (!config.params) {
      config.params = new URLSearchParams();
    }
    if (IsCTS) {
      config.params.append("networkID", PricingGuid);
      config.params.append("networkId", PricingGuid);
    }
    return config;
  },
  (error) => {
    pendingRequests--; // Decrement counter on request error
    if (pendingRequests === 0) {
      store.dispatch(setLoading({ loading: false }));
    }
    return Promise.reject(error);
  }
);

axiosEcpInstance.interceptors.response.use(
  (response) => {
    pendingRequests--;
    if (pendingRequests === 0) {
      store.dispatch(setLoading({ loading: false }));
    }

    return response;
  },
  async (error) => {
    store.dispatch(setLoading({ loading: true }));
    if (
      error.response &&
      error.response.status === 500 &&
      !error.config._retry
    ) {
      error.config._retry = true;
      try {
        store.dispatch(setLoading({ loading: true }));
        const { newAccessToken } = await createAccessToken();
        error.config.headers.Authorization = `Bearer ${newAccessToken}`;
        sessionStorage.setItem("accessToken", newAccessToken); // Update the token in session storage
        return axios(error.config); // Retry the original request with the new token
      } catch (refreshError) {
        if (pendingRequests === 0) {
          store.dispatch(setLoading({ loading: false }));
        }

        return Promise.reject(refreshError); // If token refresh fails, reject the promise
      }
    }
    if (pendingRequests === 0) {
      store.dispatch(setLoading({ loading: false }));
    }
    return Promise.reject(error);
  }
);

export default axiosEcpInstance;
